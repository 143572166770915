import { faCircleQuestion } from "@fortawesome/sharp-solid-svg-icons";
import { Box } from "@mui/material";
import { HTMLAttributes, useMemo } from "react";
import EntityPhoto from "../../../store/models/entityPhoto";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import { getEntityPhotoImageProps } from "../../screens/ProfileScreen/utils";
import { ServiceCardQuestionMarkIcon } from "../ServiceCard/ServiceCardV2/ServiceCardV2.styles";
import {
  PriceTag,
  PriceTagContainer,
  ProductBodyContainer,
  ProductCardContainer,
  ProductFooter,
  ProductImage,
} from "./ProductCard.styles";

export interface ProductCardProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "onClick"> {
  coverPhoto: EntityPhoto | null;
  name: string;
  description: string;
  totalPrice: string;
  onShowMore: () => void;
  productId: number;
  releaseId: number;
  productPurchasedByCurrentUser: boolean;
  availableQuantity?: null | number;
  purchaseLimit?: null | number;
}

export const ProductCard = ({
  name,
  description,
  totalPrice,
  onShowMore,
  coverPhoto,
  ...extraProps
}: ProductCardProps) => {
  const displayingDescription = useMemo(() => {
    // For the Product Card, we only render the first line of description
    // In addition, the maximum words to be displayed is 8, so that it doesn't span to more than 2 lines
    const res = description.split("\n")[0].split(" ").slice(0, 8).join(" ");
    return res === description ? description : `${res}...`;
  }, [description]);

  return (
    <ProductCardContainer
      onClick={onShowMore}
      {...extraProps}
      className="product-card"
    >
      <ProductImage {...getEntityPhotoImageProps(coverPhoto)} />
      <ProductBodyContainer>
        <Box sx={{ minHeight: "54px" }}>
          <Text variant={TextStyleVariant.S3}>{name}</Text>
          <Text variant={TextStyleVariant.P3} color={TextColor.SECONDARY}>
            {displayingDescription}
          </Text>
        </Box>
        <ProductFooter>
          <PriceTagContainer>
            <PriceTag
              variant={TextStyleVariant.P2}
              weight={TEXT_WEIGHT.SEMI_BOLD}
              style={{ lineHeight: 1 }}
            >
              {totalPrice}
            </PriceTag>
          </PriceTagContainer>
          <Button
            style={{ minHeight: "unset" }}
            variant={ButtonVariant.UNSTYLED}
          >
            <Box
              sx={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Text
                variant={TextStyleVariant.P2}
                weight={TEXT_WEIGHT.SEMI_BOLD}
              >
                What&apos;s Included
              </Text>
              <ServiceCardQuestionMarkIcon
                sx={{ fontSize: "12px" }}
                icon={faCircleQuestion}
              />
            </Box>
          </Button>
        </ProductFooter>
      </ProductBodyContainer>
    </ProductCardContainer>
  );
};
