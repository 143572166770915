import { Box, SelectChangeEvent } from "@mui/material";
import { toast } from "react-toastify";
import useModal from "../../../hooks/useModal";
import EntityPhoto from "../../../store/models/entityPhoto";
import {
  ReleaseTrack,
  ReleaseType,
  ReleaseTypeOptions,
} from "../../../store/models/release";
import { ModalStickyFooter } from "../../core-ui/components/BaseModal/ModalStickyFooter";
import { OptionsDropdown } from "../../core-ui/components/MUIOptionsDropdown/MUIOptionsDropdown";
import {
  Text,
  TEXT_LINE_HEIGHT,
  TEXT_WEIGHT,
} from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import { OptionType } from "../../elements/DropDownSelector/DropdownSelector";
import { getEntityPhotoImageProps } from "../../screens/ProfileScreen/utils";
import { ReleaseTypeLabel } from "../ArtistReleases/ArtistReleases.styles";
import { CenteredSoundWaveLoader } from "../CenteredSoundWaveLoader/CenteredSoundWaveLoader";
import { FeatureReleasePill } from "../FeaturedArtistRelease/FeaturedReleasePill";
import { MadeOnEngineers } from "../FeaturedArtistRelease/MadeOnEngineers";
import { UnauthenticatedModal } from "../UnauthenticatedModal/UnauthenticatedModal";
import {
  ExclusiveIconContainer,
  ProductDetailsArtistNameContainer,
  ProductDetailsContainer,
  ProductDetailsHeaderContainer,
  StyledProductImage,
} from "./ProductCardsList.styles";
import { ProductModalFooterButtons } from "./ProductModalFooterButtons";

interface ReleaseDetailsData {
  title: string;
  artistUsername: string;
  artistDisplayName?: string | null;
  releaseType: ReleaseType;
  year: string;
  featured: boolean;
}

export interface ProductModalContentProps {
  name: string;
  description: string;
  totalPrice: string;
  onChangeProduct: (productId: number) => void;
  productId: number;
  options: OptionType[];
  releaseDetails: {
    data: ReleaseDetailsData | null;
    loading: boolean;
  };
  releaseTracks: {
    data: ReleaseTrack[];
    loading: boolean;
  };
  onErrorFetchingData: () => void;
  coverPhoto: EntityPhoto | null;
  productPurchasedByCurrentUser: boolean;
  availableQuantity?: number | null;
  purchaseLimit?: number | null;
}

export const ProductModalContent = ({
  onErrorFetchingData,
  coverPhoto,
  name,
  description,
  totalPrice,
  onChangeProduct,
  productId,
  options,
  releaseDetails,
  productPurchasedByCurrentUser,
  releaseTracks,
  availableQuantity,
  purchaseLimit,
}: ProductModalContentProps) => {
  const {
    isOpen: isUnauthenticatedModalOpen,
    openModal: openUnauthenticatedModal,
    closeModal: closeUnauthenticatedModal,
  } = useModal();

  if (releaseDetails.loading || releaseTracks.loading) {
    return <CenteredSoundWaveLoader />;
  }

  // In case there was an error fetching the release details
  if (!releaseDetails.data) {
    toast.error(
      "Something was wrong fetching release details. Please try again!",
    );
    onErrorFetchingData();
    return null;
  }

  const {
    title,
    artistDisplayName,
    artistUsername,
    featured,
    releaseType,
    year,
  } = releaseDetails.data;

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <ProductDetailsContainer>
        <StyledProductImage
          {...getEntityPhotoImageProps(coverPhoto)}
          alt={name}
        />
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            rowGap: "24px",
          }}
        >
          <ProductDetailsHeaderContainer>
            <Box>
              <Text variant={TextStyleVariant.S3}>{title}</Text>
              <ProductDetailsArtistNameContainer>
                {artistDisplayName && (
                  <Text
                    variant={TextStyleVariant.P1}
                    lineHeight={TEXT_LINE_HEIGHT.NO_EXTRA_HEIGHT}
                  >
                    {artistDisplayName}
                  </Text>
                )}
                <Text
                  variant={TextStyleVariant.P1}
                  bold
                  lineHeight={TEXT_LINE_HEIGHT.NO_EXTRA_HEIGHT}
                >{`@${artistUsername}`}</Text>
              </ProductDetailsArtistNameContainer>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "flex-start",
                  columnGap: "8px",
                }}
              >
                <Text
                  variant={TextStyleVariant.P1}
                  lineHeight={TEXT_LINE_HEIGHT.NO_EXTRA_HEIGHT}
                >
                  {year}
                </Text>
                <ExclusiveIconContainer>E</ExclusiveIconContainer>
                <ReleaseTypeLabel>
                  <Text
                    color={TextColor.SECONDARY}
                    variant={TextStyleVariant.P2}
                    lineHeight={TEXT_LINE_HEIGHT.NO_EXTRA_HEIGHT}
                  >
                    {ReleaseTypeOptions.find(
                      (option) => option.value === releaseType,
                    )?.label || "N/A"}
                  </Text>
                </ReleaseTypeLabel>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                columnGap: "8px",
              }}
            >
              <MadeOnEngineers />
              {featured && <FeatureReleasePill text="Featured bundle" />}
            </Box>
          </ProductDetailsHeaderContainer>
          <Box sx={{ width: "250px", alignSelf: "flex-start" }}>
            <OptionsDropdown
              options={options}
              value={productId}
              onChange={({ target: { value } }: SelectChangeEvent<number>) => {
                if (typeof value === "number") {
                  onChangeProduct(value);
                }
              }}
            />
          </Box>
          <Box>
            {Boolean(purchaseLimit) && (
              <Text
                variant={TextStyleVariant.P2}
                style={{
                  marginBottom: "8px",
                }}
              >
                <strong>Limited quantity: </strong>
                {`${availableQuantity} / ${purchaseLimit} remaining`}
              </Text>
            )}
            <Text
              variant={TextStyleVariant.P2}
              weight={TEXT_WEIGHT.SEMI_BOLD}
              lineHeight={TEXT_LINE_HEIGHT.NO_EXTRA_HEIGHT}
            >
              Bundle description:
            </Text>
            <Text
              variant={TextStyleVariant.P2}
              style={{
                whiteSpace: "pre-wrap",
                lineHeight: "18px",
                marginTop: "16px",
              }}
              color={TextColor.SECONDARY}
            >
              {description}
            </Text>
          </Box>
          <Text variant={TextStyleVariant.P2}>
            Songs included:{" "}
            {releaseTracks.data.map((track) => track.title).join(", ")}
          </Text>
        </Box>
      </ProductDetailsContainer>
      <ModalStickyFooter
        overrideContainerStyle={(theme) => ({
          justifyContent: "flex-end",
          borderTop: `1px solid ${theme.palette.customColor.darkerMidgroundColor}`,
          padding: "24px",

          [theme.breakpoints.down("sm")]: {
            columnGap: "8px",
          },
        })}
      >
        <Text weight={TEXT_WEIGHT.SEMI_BOLD}>{totalPrice}</Text>
        <ProductModalFooterButtons
          productId={productId}
          productName={name}
          openUnauthenticatedModal={openUnauthenticatedModal}
          productPurchasedByCurrentUser={productPurchasedByCurrentUser}
          releaseTracks={releaseTracks.data}
          productsIds={options.map((option) => option.value)}
          purchaseLimit={purchaseLimit}
          availableQuantity={availableQuantity}
        />
      </ModalStickyFooter>
      {isUnauthenticatedModalOpen && (
        <UnauthenticatedModal
          showModal={true}
          closeModal={closeUnauthenticatedModal}
          message={"You need to sign in to make a purchase"}
        />
      )}
    </Box>
  );
};
