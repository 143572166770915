import { Box } from "@mui/material";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React, { useEffect } from "react";
import { AuthFlows, AuthSteps } from "../../../constants/authSteps";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import useModal from "../../../hooks/useModal";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { AccountCreateComplete } from "./AccountCreateComplete";
import { AddPhone } from "./AddPhone";
import {
  authModalOpenAtom,
  currentFlowAtom,
  currentSignUpStepAtom,
  readCurrentFlowFromUrl,
  readCurrentStepFromUrl,
} from "./atoms";
import AuthBannerInfoWrapper from "./AuthBannerInfoWrapper";
import { AuthModalContainer } from "./AuthModal.styles";
import { BuildTeam } from "./BuildTeam";
import { EmailAuth } from "./EmailAuth";
import { LabelSelection } from "./LabelSelection";
import { ProfileType } from "./ProfileType";
import { StartSignUp } from "./StartSignUp";
import StudioContact from "./StudioContact";
import StudioLocation from "./StudioLocation";
import { UsernameSelection } from "./UsernameSelection";
import VerifyEmail from "./VerifyEmail";
import { VerifyPhone } from "./VerifyPhone";
import { showBottomAndTopNavAtom } from "../../../atoms/navAtoms";
import { datadogRum } from "@datadog/browser-rum";
import { StartSignIn } from "./StartSignIn";

const stepToForm: Record<number, React.FC> = {
  [AuthSteps.START_SIGNUP]: StartSignUp,
  [AuthSteps.START_LOGIN]: StartSignIn,
  [AuthSteps.LOGIN]: EmailAuth,
  [AuthSteps.EMAIL_SIGNUP]: EmailAuth,
  [AuthSteps.PROFILE_TYPE]: ProfileType,
  [AuthSteps.USERNAME]: UsernameSelection,
  [AuthSteps.VERIFY_EMAIL]: VerifyEmail,
  [AuthSteps.ADD_PHONE]: AddPhone,
  [AuthSteps.VERIFY_PHONE]: VerifyPhone,
  [AuthSteps.ADD_STUDIO_LOCATION]: StudioLocation,
  [AuthSteps.ADD_STUDIO_CONTACT]: StudioContact,
  [AuthSteps.COMPLETE]: AccountCreateComplete,
  [AuthSteps.AANDR_LABEL_SELECTION]: LabelSelection,
  [AuthSteps.AANDR_USERNAME]: UsernameSelection,
  [AuthSteps.AANDR_ADD_PHONE]: AddPhone,
  [AuthSteps.AANDR_VERIFY_PHONE]: VerifyPhone,
  [AuthSteps.AANDR_BUILD_TEAM]: BuildTeam,
};

export const AuthModal = () => {
  const { toggleModal } = useModal();
  const [step, setStep] = useAtom(currentSignUpStepAtom);
  const setFlow = useSetAtom(currentFlowAtom);
  const stepFromUrl = useAtomValue(readCurrentStepFromUrl);
  const flowFromUrl = useAtomValue(readCurrentFlowFromUrl);
  const [authModalOpen, setAuthModalOpen] = useAtom(authModalOpenAtom);
  const StepForm = stepToForm[step];
  const nonAuthBannerSteps = [
    AuthSteps.PROFILE_TYPE,
    AuthSteps.AANDR_LABEL_SELECTION,
    AuthSteps.COMPLETE,
  ];
  const isNonAuthBannerStep = nonAuthBannerSteps.includes(step);
  const { isDesktop } = useMediaQueryBreakpoint();
  const setShowNavState = useSetAtom(showBottomAndTopNavAtom);

  // We want to record the session while the modal is open, and stop it when the modal closes if it wasn't recording before loading
  useEffect(() => {
    const hadSessionRunning = datadogRum.getSessionReplayLink();
    if (!hadSessionRunning) {
      datadogRum.startSessionReplayRecording();
    }
    return () => {
      if (!hadSessionRunning) {
        datadogRum.stopSessionReplayRecording();
      }
    };
  }, []);

  useEffect(() => {
    setShowNavState(undefined);
    if (isDesktop) {
      setShowNavState(true);
      return;
    }
    if (
      step === AuthSteps.EMAIL_SIGNUP ||
      step === AuthSteps.LOGIN ||
      step === AuthSteps.START_SIGNUP
    ) {
      setShowNavState(true);
      return;
    }
    setShowNavState(undefined);
  }, [isDesktop, setShowNavState, step]);

  useEffect(() => {
    if (
      !StepForm ||
      stepFromUrl === AuthSteps.NONE ||
      flowFromUrl === AuthFlows.NONE
    ) {
      setAuthModalOpen(false);
    } else {
      setFlow(flowFromUrl);
      setStep(stepFromUrl);
      if (!authModalOpen) setAuthModalOpen(true);
    }
  }, []);

  return !isDesktop && authModalOpen ? (
    <Box
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ...(step === AuthSteps.COMPLETE
          ? { height: "100%", justifyContent: "center" }
          : {}),
      }}
    >
      {StepForm ? <StepForm /> : null}
    </Box>
  ) : (
    <BaseModal
      header=""
      open={authModalOpen}
      setOpen={toggleModal}
      showModalHeader={false}
      style={{
        maxWidth:
          isNonAuthBannerStep && step !== AuthSteps.COMPLETE
            ? "1224px"
            : "840px",
        boxShadow: "0px 2px 30px 0px rgba(0, 0, 0, 0.15)",
        maxHeight: "90vh",
        overflow: "auto",
      }}
      modalBodyStyle={{ padding: 0, width: "100%" }}
    >
      <AuthModalContainer>
        <AuthBannerInfoWrapper />
        <Box
          style={{
            width: isNonAuthBannerStep ? "100%" : "50%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {StepForm ? <StepForm /> : null}
        </Box>
      </AuthModalContainer>
    </BaseModal>
  );
};
